import React from 'react';

const JSicon = () => {
  return (
    <svg className=" iconSize" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.87 152.04">
      <rect class="cls-20" x="2.08" y="2.11" width="155.87" height="148.04" rx="37.01" ry="37.01"/>
        <path class="cls-10" d="m121.74,2c19.95,0,36.13,16.17,36.13,36.13v75.79c0,19.95-16.17,36.13-36.13,36.13H38.13c-19.95,0-36.13-16.17-36.13-36.13V38.13C2,18.17,18.17,2,38.13,2h83.62m0-2H38.13C17.1,0,0,17.1,0,38.13v75.79c0,21.02,17.1,38.13,38.13,38.13h83.62c21.02,0,38.13-17.1,38.13-38.13V38.13c0-21.02-17.1-38.13-38.13-38.13h0Z"/>
      <path class="cls-30" d="m24.36,16.48v111.39h111.39V16.48H24.36Zm60.71,89.58c-1.54,16.03-24.75,17.69-30.65,4.14l8.25-5.3c1.77,7.07,11.79,6.48,11.84.82v-37.95h10.56v38.3Zm28.29,12.98h-7.66c-6.29-.02-11.2-4.41-15.32-10.02l8.84-5.3c7.66,12.38,24.16,4.72,15.32-2.36l-14.15-7.66c-20.63-18.86,10.02-37.72,23.58-18.27l-8.25,5.3c-5.89-8.84-16.5-2.36-8.84,4.72l12.38,5.89c12.25,8.14,10.61,27.7-5.89,27.7Z"/>
    </svg>
  );
}

export default JSicon;