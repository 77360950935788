import React from 'react';

const Facebookicon = () => {
  return (
   <svg className=" iconSize cursor-pointer" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.84 116.88">
   <rect class="cls-20" x="2.06" y="2.09" width="118.84" height="112.87" rx="37.01" ry="37.01"/>
   <path class="cls-10" d="m84.72,2c19.95,0,36.13,16.17,36.13,36.13v40.62c0,19.95-16.17,36.13-36.13,36.13h-46.59c-19.95,0-36.13-16.17-36.13-36.13v-40.62C2,18.17,18.17,2,38.13,2h46.59m0-2h-46.59C17.1,0,0,17.1,0,38.13v40.62c0,21.02,17.1,38.13,38.13,38.13h46.59c21.02,0,38.13-17.1,38.13-38.13v-40.62C122.84,17.1,105.74,0,84.72,0h0Z"/>
 <path class="cls-10" d="m111.37,58.77c0,24.84-18.15,45.44-41.9,49.28v-34.34h11.66l2.55-15.26h-14.21v-10.98c.7-3.63,2.83-5.95,7.65-5.83h6.56v-12.75c-17.13-2.91-27.69,1.46-29.88,14.57v15.3h-12.75v14.57h12.75v34.76c-23.94-3.68-42.27-24.37-42.27-49.34,0-27.57,22.35-49.92,49.92-49.92s49.92,22.35,49.92,49.92Z"/>
</svg>
  );
}

export default Facebookicon;
