import React from 'react';

const Githubicon2 = () => {
  return (
   <svg className=" iconSize2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.84 116.88">
     <rect class="cls-222 cursor-pointer" x="2.06" y="2.09" width="118.84" height="112.87" rx="37.01" ry="37.01"/>
     <path class="cls-111 cursor-pointer" d="m84.72,2c19.95,0,36.13,16.17,36.13,36.13v40.62c0,19.95-16.17,36.13-36.13,36.13h-46.59c-19.95,0-36.13-16.17-36.13-36.13v-40.62C2,18.17,18.17,2,38.13,2h46.59m0-2h-46.59C17.1,0,0,17.1,0,38.13v40.62c0,21.02,17.1,38.13,38.13,38.13h46.59c21.02,0,38.13-17.1,38.13-38.13v-40.62C122.84,17.1,105.74,0,84.72,0h0Z"/>    <path class="cls-111 cursor-pointer" d="m111.02,58.48c0,22.6-15.13,41.67-35.81,47.64-.58-.26-1.04-.78-1.37-1.55v-16.27c-.29-2.47-1.34-4.92-3.1-7.36,20.14-1.16,29.05-21.69,17.43-37.95,1.15-5.06,1.43-9.72-.93-13.17-5.14.02-9.52,1.99-13.4,5.27-8.22-2.35-16.35-2.4-24.4,0-4.16-3.33-8.75-5.27-13.94-5.27-2.1,4.43-2.07,8.81-.77,13.17-11.23,14.33-3.49,37.57,17.43,37.95-1.68,1.69-2.56,4.03-3.1,6.66-15.49,4.57-11.62-7.05-20.53-8.98-3.87-1.55-5.03.77-1.55,2.71,6.58,3.1,3.87,17.43,22.08,13.94v9.29c-.26.86-.82,1.31-1.58,1.49-20.58-6.03-35.6-25.05-35.6-47.57,0-27.38,22.2-49.57,49.57-49.57s49.57,22.2,49.57,49.57Z"/>
     <path class="cls-333 cursor-pointer" d="m70.75,80.94c1.76,2.44,2.81,4.89,3.1,7.36v16.27c.33.77.79,1.29,1.37,1.55-4.37,1.26-8.99,1.94-13.76,1.94s-9.54-.7-13.97-2c.76-.18,1.32-.62,1.58-1.49v-9.29c-18.2,3.49-15.49-10.84-22.08-13.94-3.49-1.94-2.32-4.26,1.55-2.71,8.91,1.94,5.03,13.55,20.53,8.98.53-2.63,1.42-4.97,3.1-6.66-20.91-.39-28.66-23.62-17.43-37.95-1.29-4.35-1.33-8.74.77-13.17,5.19,0,9.78,1.94,13.94,5.27,8.05-2.4,16.18-2.35,24.4,0,3.88-3.28,8.26-5.25,13.4-5.27,2.36,3.45,2.08,8.11.93,13.17,11.62,16.27,2.71,36.79-17.43,37.95Z"/>
     <path class="cls-333 cursor-pointer" d="m75.22,106.12c.56.24,1.23.25,2.04,0"/>
     <path class="cls-333 cursor-pointer" d="m45.96,106.12c.56.05,1.07.04,1.52-.06"/>
   </svg>
  );
}

export default Githubicon2;
