import React from 'react';

const Rotatephoneicon = () => {
  return (
   <svg className=" iconSize" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256.3 255.02">
   <path class="cls-10" d="m250.29,49.02c-6.36-6.77-14.58-6.99-16-7h-116.93c-2.45.23-9.67,1.21-16.07,7-7.88,7.13-8.84,16.4-9,18.5v161.5c.31,2.56,1.58,10.25,8,17,6.89,7.24,15.21,8.67,17.67,9h114.33c1.71-.11,11.15-.89,18-9,5.78-6.84,6.01-14.52,6-16.63V65.02c.05-1.48.18-9.41-6-16Zm-8,190c-3.99,5.38-9.78,6.47-11,6.67h-112c-1.85-.33-6.74-1.44-11-5.67-5.15-5.11-5.86-11.3-6-13V67.52c.35-1.65,1.58-6.47,6-10.5s9.32-4.81,11-5h112c.98-.01,5.94.06,10,4,4.27,4.14,4.32,9.41,4.31,10.31v161.69c.07,1.43.13,6.35-3.31,11Z"/>
   <rect class="cls-41" x="123.29" y="76.02" width="101.93" height="139.44"/>
   <path class="cls-41" d="m245.6,228.02V66.33c.01-.9-.04-6.17-4.31-10.31-4.06-3.94-9.02-4.01-10-4h-112c-1.68.19-6.58.97-11,5s-5.65,8.85-6,10.5v159.5c.14,1.7.85,7.89,6,13,4.26,4.23,9.15,5.34,11,5.67h112c1.22-.2,7.01-1.29,11-6.67,3.44-4.65,3.38-9.57,3.31-11Zm-71.31,15c-3.87,0-7-3.13-7-7s3.13-7,7-7,7,3.13,7,7-3.13,7-7,7Zm62-24.59c0,.66-.09,2.11-1,3.59-1.33,2.16-3.45,2.84-4,3h-115c-.52-.33-1.34-.96-2-2-1.06-1.68-1.04-3.36-1-4V71.46c-.08-.15-.87-1.76,0-3.44.92-1.78,2.87-1.99,3-2h115.74c.39.07,2.09.42,3.26,2,.92,1.24.99,2.53,1,3v147.41Z"/>
   <rect class="cls-41" x="123.29" y="76.02" width="101.93" height="139.44"/>
   <path class="cls-41" d="m245.6,228.02V66.33c.01-.9-.04-6.17-4.31-10.31-4.06-3.94-9.02-4.01-10-4h-112c-1.68.19-6.58.97-11,5s-5.65,8.85-6,10.5v159.5c.14,1.7.85,7.89,6,13,4.26,4.23,9.15,5.34,11,5.67h112c1.22-.2,7.01-1.29,11-6.67,3.44-4.65,3.38-9.57,3.31-11Zm-71.31,15c-3.87,0-7-3.13-7-7s3.13-7,7-7,7,3.13,7,7-3.13,7-7,7Zm62-24.59c0,.66-.09,2.11-1,3.59-1.33,2.16-3.45,2.84-4,3h-115c-.52-.33-1.34-.96-2-2-1.06-1.68-1.04-3.36-1-4V71.46c-.08-.15-.87-1.76,0-3.44.92-1.78,2.87-1.99,3-2h115.74c.39.07,2.09.42,3.26,2,.92,1.24.99,2.53,1,3v147.41Z"/>
   <path class="cls-10" d="m235.29,68.02c-1.17-1.58-2.87-1.93-3.26-2h-115.74c-.13.01-2.08.22-3,2-.87,1.68-.08,3.29,0,3.44v147.56c-.04.64-.06,2.32,1,4,.66,1.04,1.48,1.67,2,2h115c.55-.16,2.67-.84,4-3,.91-1.48,1-2.93,1-3.59V71.02c-.01-.47-.08-1.76-1-3Zm-10.07,147.44h-101.93V76.02h101.93v139.44Z"/>
   <rect class="cls-41" x="123.29" y="76.02" width="101.93" height="139.44"/>
   <rect class="cls-41" x="123.29" y="76.02" width="101.93" height="139.44"/>
   <path class="cls-10" d="m103.29,6.02c-.54,2.33-2.7,3.38-3,3.52h-44c-.92-.03-6.61-.08-11,4.48-4.74,4.91-4.1,11.17-4,12v48.7l22.74-22.7c2.09-1.17,4.71-.74,6.26,1,1.46,1.63,1.67,4.09.5,6-11.5,11.67-23,23.33-34.5,35L.29,58.02c-.81-2.44.12-4.96,2-6,1.92-1.06,4.02-.11,4.26,0,8.25,7.67,16.49,15.33,24.74,23V25.02c-.07-1.79-.2-10.02,6-17C44.75-.37,55.23-.05,56.62.02h43.67c2.33,1.26,3.52,3.76,3,6Z"/>
   <circle class="cls-10" cx="174.29" cy="236.02" r="7"/>
</svg>
  );
}

export default Rotatephoneicon;