import React from 'react';

const Instagramicon = () => {
  return (
   <svg className=" iconSize cursor-pointer" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.84 116.88">
        <rect class="cls-222" x="2.06" y="2.09" width="118.84" height="112.87" rx="37.01" ry="37.01"/>
        <path class="cls-111" d="m84.72,2c19.95,0,36.13,16.17,36.13,36.13v40.62c0,19.95-16.17,36.13-36.13,36.13h-46.59c-19.95,0-36.13-16.17-36.13-36.13v-40.62C2,18.17,18.17,2,38.13,2h46.59m0-2h-46.59C17.1,0,0,17.1,0,38.13v40.62c0,21.02,17.1,38.13,38.13,38.13h46.59c21.02,0,38.13-17.1,38.13-38.13v-40.62C122.84,17.1,105.74,0,84.72,0h0Z"/>
        <path class="cls-111" d="m85.46,17.09h-48.01c-10.8,0-19.57,8.25-19.57,18.42v45.95c0,10.17,8.76,18.42,19.57,18.42h48.01c10.8,0,19.57-8.25,19.57-18.42v-45.95c0-10.17-8.76-18.42-19.57-18.42Zm8.11,54.42c0,9.71-8.37,17.59-18.68,17.59h-26.86c-10.32,0-18.68-7.87-18.68-17.59v-26.05c0-9.71,8.37-17.59,18.68-17.59h26.86c10.32,0,18.68,7.87,18.68,17.59v26.05Z"/>
        <path class="cls-333" d="m61.45,42.89c-9.15,0-16.57,6.98-16.57,15.59s7.42,15.59,16.57,15.59,16.57-6.98,16.57-15.59-7.42-15.59-16.57-15.59Zm-.1,25.8c-6.04,0-10.94-4.61-10.94-10.3s4.9-10.3,10.94-10.3,10.94,4.61,10.94,10.3-4.9,10.3-10.94,10.3Z"/>
        <ellipse class="cls-333" cx="78.74" cy="42.21" rx="3.99" ry="3.75"/>
        <path class="cls-333" d="m74.88,27.87h-26.86c-10.32,0-18.68,7.87-18.68,17.59v26.05c0,9.71,8.37,17.59,18.68,17.59h26.86c10.32,0,18.68-7.87,18.68-17.59v-26.05c0-9.71-8.37-17.59-18.68-17.59Zm13.37,43.38c0,6.88-5.92,12.45-13.23,12.45h-27.13c-7.31,0-13.23-5.58-13.23-12.45v-25.54c0-6.88,5.92-12.45,13.23-12.45h27.13c7.31,0,13.23,5.58,13.23,12.45v25.54Z"/>
        <path class="cls-333" d="m61.45,42.89c-9.15,0-16.57,6.98-16.57,15.59s7.42,15.59,16.57,15.59,16.57-6.98,16.57-15.59-7.42-15.59-16.57-15.59Zm-.1,25.8c-6.04,0-10.94-4.61-10.94-10.3s4.9-10.3,10.94-10.3,10.94,4.61,10.94,10.3-4.9,10.3-10.94,10.3Z"/>
        <ellipse class="cls-333" cx="78.74" cy="42.21" rx="3.99" ry="3.75"/>
        <path class="cls-333" d="m74.88,27.87h-26.86c-10.32,0-18.68,7.87-18.68,17.59v26.05c0,9.71,8.37,17.59,18.68,17.59h26.86c10.32,0,18.68-7.87,18.68-17.59v-26.05c0-9.71-8.37-17.59-18.68-17.59Zm13.37,43.38c0,6.88-5.92,12.45-13.23,12.45h-27.13c-7.31,0-13.23-5.58-13.23-12.45v-25.54c0-6.88,5.92-12.45,13.23-12.45h27.13c7.31,0,13.23,5.58,13.23,12.45v25.54Z"/>
        <path class="cls-111" d="m75.02,33.26h-27.13c-7.31,0-13.23,5.58-13.23,12.45v25.54c0,6.88,5.92,12.45,13.23,12.45h27.13c7.31,0,13.23-5.58,13.23-12.45v-25.54c0-6.88-5.92-12.45-13.23-12.45Zm-13.57,40.82c-9.15,0-16.57-6.98-16.57-15.59s7.42-15.59,16.57-15.59,16.57,6.98,16.57,15.59-7.42,15.59-16.57,15.59Zm17.29-28.11c-2.2,0-3.99-1.68-3.99-3.75s1.79-3.75,3.99-3.75,3.99,1.68,3.99,3.75-1.79,3.75-3.99,3.75Z"/>
        <path class="cls-333" d="m61.45,42.89c-9.15,0-16.57,6.98-16.57,15.59s7.42,15.59,16.57,15.59,16.57-6.98,16.57-15.59-7.42-15.59-16.57-15.59Zm-.1,25.8c-6.04,0-10.94-4.61-10.94-10.3s4.9-10.3,10.94-10.3,10.94,4.61,10.94,10.3-4.9,10.3-10.94,10.3Z"/>
        <ellipse class="cls-333" cx="78.74" cy="42.21" rx="3.99" ry="3.75"/>
        <path class="cls-333" d="m61.45,42.89c-9.15,0-16.57,6.98-16.57,15.59s7.42,15.59,16.57,15.59,16.57-6.98,16.57-15.59-7.42-15.59-16.57-15.59Zm-.1,25.8c-6.04,0-10.94-4.61-10.94-10.3s4.9-10.3,10.94-10.3,10.94,4.61,10.94,10.3-4.9,10.3-10.94,10.3Z"/>
        <ellipse class="cls-111" cx="61.35" cy="58.38" rx="10.94" ry="10.3"/>
        <ellipse class="cls-333" cx="78.74" cy="42.21" rx="3.99" ry="3.75"/>
</svg>
  );
}

export default Instagramicon;
