import React from 'react';

const Sassicon = () => {
  return (
   <svg className=" iconSize" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 159.87 152.04">
   <rect class="cls-21" x="2.08" y="2.11" width="155.87" height="148.04" rx="37.01" ry="37.01"/>
   <path class="cls-11" d="m121.74,2c19.95,0,36.13,16.17,36.13,36.13v75.79c0,19.95-16.17,36.13-36.13,36.13H38.13c-19.95,0-36.13-16.17-36.13-36.13V38.13C2,18.17,18.17,2,38.13,2h83.62m0-2H38.13C17.1,0,0,17.1,0,38.13v75.79c0,21.02,17.1,38.13,38.13,38.13h83.62c21.02,0,38.13-17.1,38.13-38.13V38.13c0-21.02-17.1-38.13-38.13-38.13h0Z"/>
   <path class="cls-41" d="m97.73,94.13c-.05,3.91-2.34,6.58-6.88,8.03l6.88-8.03Z"/>
   <path class="cls-41" d="m71.94,77.05c-.99,7.01-7.77,26.29-10.49,24.72-3.89-12.55,5.08-27.36,10.49-24.72Z"/>
   <path class="cls-31" d="m20.92,76.93l18.6,13.59-.64.36c-20.64,7.07-30.19,28.09-15.05,32.45h5.43c16.44-3.67,23.65-19.12,17.35-31.89-.62-1.25-1.36-2.46-2.23-3.64-.82-1.12-1.77-2.19-2.83-3.23l-16.05-12.99C6.78,44.06,81.3,13.11,92.11,33.17c6.39,7.83-23.42,41.85-47.49,23.88,3.44,19.49,51.97,8.41,55.41-18.34l.38-6.11C92.38-5.62-18.44,42.92,20.92,76.93Zm5.73,38.22c-5.73-7.26,6.08-16.01,16.05-21.78,3.69,10.39-6.5,24.08-16.05,21.78Z"/>
   <path class="cls-41" d="m26.65,115.14c-5.73-7.26,6.08-16.01,16.05-21.78,3.69,10.39-6.5,24.08-16.05,21.78Z"/>
   <path class="cls-31" d="m119.9,81.13c-1.75-2.59-2.97-5.89-3.33-10.33l1.04-4.95c4.2-3.82-6.05-5.64-8.41-1.53-.49,4.78-4.49,11.91-9.17,19.49-1.36-2.41-2.68-6.11-2.68-11.08,4.12-5.76,1.97-7.73-6.11-6.11-2.17,6.42-7.44,18.03-12.89,29.92-2.25,5.77-2.6,4.09-2.02-1.25l3.44-12.61c-.27-3.03-1.82-3.76-4.59-2.29,1.91-11.85-8.12-11.58-16.81-.38l-13.99,7.82c.88,1.18,1.62,2.4,2.23,3.64l7.93-4.58c-2.27,10.28-1.64,18.62,3.82,23.69,1.53.47,3,.72,4.2,0,3.3-3.56,5.63-7.83,7.26-12.61-.38,11.46,3.36,14.64,9.55,6.11,4.97-9.94,12.23-25.22,12.23-25.22.71,3.95,2.1,7.77,4.05,11.46-4.44,7.64-13.99,12.99-8.64,16.43,1.73.55,3.29.63,4.59,0,9.2-2.76,14.85-7.64,10.7-18.34l8.03-14.14c.97,4.23,2.16,8.08,3.82,11.08-6.62,5.97-10.7,11.85-6.5,16.81.76.38,1.91.38,3.44,0,8.69-3.5,13.73-8.46,10.32-16.81,17.96-6.88,36.3,6.11,22.93,17.2,16.43-1.91,6.11-32.87-24.46-21.4Zm-58.45,20.63c-3.89-12.55,5.08-27.36,10.49-24.72-.99,7.01-7.77,26.29-10.49,24.72Zm29.41.39l6.88-8.03c-.05,3.91-2.34,6.58-6.88,8.03Zm20.25-4.59c-.76-3.44,2.29-7.51,5.35-9.55,1.17.85-.26,8.87-5.35,9.55Z"/>
   <path class="cls-41" d="m111.11,97.57c-.76-3.44,2.29-7.51,5.35-9.55,1.17.85-.26,8.87-5.35,9.55Z"/>
   <line class="cls-41" x1="46.61" y1="91.44" x2="44.61" y2="92.6"/>
   <line class="cls-41" x1="40.79" y1="91.45" x2="39.52" y2="90.52"/>
   <path class="cls-41" d="m26.65,115.14c-5.73-7.26,6.08-16.01,16.05-21.78,3.69,10.39-6.5,24.08-16.05,21.78Z"/>
   </svg>
  );
}

export default Sassicon;
