import React from 'react';

const Illustratoricon = () => {
  return (
   <svg className=" iconSize" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.2 147.61">
   <rect class="cls-21" x="2.08" y="2.11" width="151.2" height="143.61" rx="37.01" ry="37.01"/>
   <path class="cls-11" d="m117.08,2c19.95,0,36.13,16.17,36.13,36.13v71.36c0,19.95-16.17,36.13-36.13,36.13H38.13c-19.95,0-36.13-16.17-36.13-36.13V38.13C2,18.17,18.17,2,38.13,2h78.95m0-2H38.13C17.1,0,0,17.1,0,38.13v71.36c0,21.02,17.1,38.13,38.13,38.13h78.95c21.02,0,38.13-17.1,38.13-38.13V38.13c0-21.02-17.1-38.13-38.13-38.13h0Z"/>
   <path class="cls-41" d="m96.91,98.13h11.37v-40.3h-11.37v40.3Zm5.46-44.38c3.45,0,6.24-2.79,6.24-6.24s-2.79-6.24-6.24-6.24-6.24,2.79-6.24,6.24,2.79,6.24,6.24,6.24Zm-42.3-8.78l-18.03,53.16h11.09l4.16-12.02h18.03l4.32,12.02h12.19l-18.9-53.16h-12.86Zm0,30.05l6.16-20.06,6.16,20.06h-12.32Z"/>
   <path class="cls-31" d="m115.19,15.38H40.1c-12.45,0-22.55,10.1-22.55,22.55v71.85c0,12.45,10.1,22.55,22.55,22.55h75.09c12.45,0,22.55-10.1,22.55-22.55V37.93c0-12.45-10.1-22.55-22.55-22.55Zm-35.53,82.74l-4.32-12.02h-18.03l-4.16,12.02h-11.09l18.03-53.16h12.86l18.9,53.16h-12.19Zm28.63,0h-11.37v-40.3h11.37v40.3Zm-5.91-44.38c-3.45,0-6.24-2.79-6.24-6.24s2.79-6.24,6.24-6.24,6.24,2.79,6.24,6.24-2.79,6.24-6.24,6.24Z"/>
   <path class="cls-41" d="m72.94,44.97h-12.86l-18.03,53.16h11.09l4.16-12.02h18.03l4.32,12.02h12.19l-18.9-53.16Zm-12.86,30.05l6.16-20.06,6.16,20.06h-12.32Z"/>
   <polygon class="cls-31" points="72.39 75.02 60.08 75.02 66.23 54.96 72.39 75.02"/>
   <rect class="cls-41" x="96.91" y="57.83" width="11.37" height="40.3"/>
   <circle class="cls-41" cx="102.37" cy="47.51" r="6.24"/>
</svg>
  );
}

export default Illustratoricon;
