import React from 'react';

const Linkdinicon = () => {
  return (
    <svg className=" iconSize cursor-pointer" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.84 116.88">
     <rect class="cls-20" x="2.06" y="2.09" width="118.84" height="112.87" rx="37.01" ry="37.01"/>
     <path class="cls-10" d="m84.72,2c19.95,0,36.13,16.17,36.13,36.13v40.62c0,19.95-16.17,36.13-36.13,36.13h-46.59c-19.95,0-36.13-16.17-36.13-36.13v-40.62C2,18.17,18.17,2,38.13,2h46.59m0-2h-46.59C17.1,0,0,17.1,0,38.13v40.62c0,21.02,17.1,38.13,38.13,38.13h46.59c21.02,0,38.13-17.1,38.13-38.13v-40.62C122.84,17.1,105.74,0,84.72,0h0Z"/>
     <path class="cls-10" d="m94.31,18.69H28.59c-4.99,0-9.03,3.84-9.03,8.58v62.43c0,4.74,4.04,8.58,9.03,8.58h65.72c4.99,0,9.03-3.84,9.03-8.58V27.26c0-4.74-4.04-8.58-9.03-8.58Zm-49.42,68.85h-12.47v-38.13h12.47v38.13Zm-6.43-42.94c-4.2,0-7.6-3.23-7.6-7.22s3.4-7.22,7.6-7.22,7.6,3.23,7.6,7.22-3.4,7.22-7.6,7.22Zm53.19,42.94h-12.6v-22.58c-.65-8.14-13.12-8.51-14.29,0v22.58h-11.95v-38.13h11.95v4.56c9.35-10.49,24.94-6.05,26.89,6.17v27.39Z"/>
    </svg>
  );
}

export default Linkdinicon;
