import React from 'react';

const Premiericon = () => {
  return (
   <svg className=" iconSize" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 155.2 147.61">
   <rect class="cls-21" x="2.08" y="2.11" width="151.2" height="143.61" rx="37.01" ry="37.01"/>
   <path class="cls-11" d="m117.08,2c19.95,0,36.13,16.17,36.13,36.13v71.36c0,19.95-16.17,36.13-36.13,36.13H38.13c-19.95,0-36.13-16.17-36.13-36.13V38.13C2,18.17,18.17,2,38.13,2h78.95m0-2H38.13C17.1,0,0,17.1,0,38.13v71.36c0,21.02,17.1,38.13,38.13,38.13h78.95c21.02,0,38.13-17.1,38.13-38.13V38.13c0-21.02-17.1-38.13-38.13-38.13h0Z"/>
   <path class="cls-41" d="m101.78,64.47l-1.09-5.11h-9.59v40.19h11.5v-26.73c3.06-2.44,7.29-3.57,12.45-3.63v-11.19c-5.84.18-10.3,2.29-13.28,6.47Zm-34.35-18.38h-21.24v53.45h10.98v-17.18h10.26c21.72-2.22,23.11-31.32,0-36.27Zm-2.73,26.73h-7.89v-16.69h7.89c11.98,1.39,11.06,15.24,0,16.69Z"/>
   <path class="cls-31" d="m115.2,15.38H40.08c-12.44,0-22.53,10.09-22.53,22.53v71.89c0,12.44,10.09,22.53,22.53,22.53h75.12c12.44,0,22.53-10.09,22.53-22.53V37.92c0-12.44-10.09-22.53-22.53-22.53Zm-47.78,66.97h-10.26v17.18h-10.98v-53.45h21.24c23.11,4.95,21.72,34.05,0,36.27Zm47.63-13.17c-5.16.06-9.4,1.19-12.45,3.63v26.73h-11.5v-40.19h9.59l1.09,5.11c2.97-4.18,7.44-6.29,13.28-6.47v11.19Z"/>
   <path class="cls-41" d="m67.43,46.09h-21.24v53.45h10.98v-17.18h10.26c21.72-2.22,23.11-31.32,0-36.27Zm-2.73,26.73h-7.89v-16.69h7.89c11.98,1.39,11.06,15.24,0,16.69Z"/>
   <path class="cls-31" d="m64.69,72.81h-7.89v-16.69h7.89c11.98,1.39,11.06,15.24,0,16.69Z"/>
   <path class="cls-41" d="m115.05,58v11.19c-5.16.06-9.4,1.19-12.45,3.63v26.73h-11.5v-40.19h9.59l1.09,5.11c2.97-4.18,7.44-6.29,13.28-6.47Z"/>
</svg>
  );
}

export default Premiericon;
