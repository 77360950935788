import React from 'react';

const HTMLicon = () => {
  return (
    <svg className=" iconSize" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 175.41 166.8">
            <rect className="cls-20" x="2.09" y="2.12" width="171.41" height="162.8" rx="37.01" ry="37.01"/>
            <path className="cls-10" d="m137.28,2c19.95,0,36.13,16.17,36.13,36.13v90.55c0,19.95-16.17,36.13-36.13,36.13H38.13c-19.95,0-36.13-16.17-36.13-36.13V38.13C2,18.17,18.17,2,38.13,2h99.16m0-2H38.13C17.1,0,0,17.1,0,38.13v90.55c0,21.02,17.1,38.13,38.13,38.13h99.16c21.02,0,38.13-17.1,38.13-38.13V38.13c0-21.02-17.1-38.13-38.13-38.13h0Z"/>
          <path className="cls-30" d="m29.09,16.06l10.97,119.89,48.03,14.05,48.03-13.29,10.97-120.65H29.09Zm96.09,39.71h-57.14l.76,14.37h54.46l-4.54,45.38-30.43,8.93-30.84-8.93-2.27-23.45h15.88l.76,12.1,16.26,4.58,16.26-4.58,1.51-18.15-50.68-.76-3.78-45.38h73.78v15.88Z"/>
    </svg>
  );
}

export default HTMLicon;
